import Img, { FluidObject } from "gatsby-image"
import React, { FC } from "react"

type Props = {
  image: FluidObject
  imageRight?: boolean
}
export const DualPaneImage: FC<Props> = ({
  children,
  image,
  imageRight = false,
}) => {
  return (
    <div className="grid grid-cols-1 my-8 md:grid-cols-2">
      {!imageRight && <Img fluid={image} />}
      <div className="flex flex-col justify-center p-12 bg-main-smoke">
        {children}
      </div>
      {imageRight && <Img fluid={image} />}
    </div>
  )
}

export default DualPaneImage
