import BlockContent from "@sanity/block-content-to-react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React, { FC } from "react"
import { Banner } from "../components/banner"
import Layout from "../components/layout"
import LeftOverlay from "../components/left-overlay"
import Qoute from "../components/Qoute"
import { blockSerializer } from "../utils/sanity-helper"
import { dataset, projectId } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"

export const query = graphql`
  query {
    frontpage: sanityFrontpage {
      id
      _rawBody
      _rawMainImage
      mainImage {
        asset {
          fluid(maxWidth: 1152, maxHeight: 420) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }

      banner {
        _rawImage
        _rawIndhold
        link
        image {
          asset {
            fluid(maxWidth: 576, maxHeight: 330) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }

      _rawAfterImage
      quote
    }
    senestemenu: allSanityMenu(
      sort: { fields: [aar, uge], order: [DESC, DESC] }
      limit: 1
    ) {
      nodes {
        uge
        aar
      }
    }
  }
`

const Index: FC<any> = (props) => {
  const aar = props.data.senestemenu.nodes[0].aar
  const uge = props.data.senestemenu.nodes[0].uge

  return (
    <Layout title="Velkommen" aar={aar} uge={uge}>
      <LeftOverlay
        title="Øland"
        // button="Bestil her"
        // onClick={() => navigate(getBestillingLink(aar, uge))}
      >
        <Img
          backgroundColor
          fluid={getFluidImage(
            props.data.frontpage._rawMainImage,
            props.data.frontpage.mainImage.asset.fluid,
            1152,
            420
          )}
        />
      </LeftOverlay>

      <BlockContent
        blocks={props.data.frontpage._rawBody}
        serializers={blockSerializer()(
          <div className="grid max-w-3xl grid-cols-1 gap-4 px-8 mx-auto md:grid-rows-2 md:grid-flow-col md:grid-cols-2 md:px-0" />,
          "normal"
        )}
        projectId={projectId}
        dataset={dataset}
      />

      <Banner banner={props.data.frontpage.banner} />

      <BlockContent
        blocks={props.data.frontpage._rawAfterImage}
        serializers={blockSerializer()(
          <div className="grid max-w-3xl grid-cols-1 gap-4 px-8 mx-auto md:grid-cols-2 md:px-0" />,
          "normal"
        )}
        projectId={projectId}
        dataset={dataset}
      />

      <Qoute>{props.data.frontpage.quote}</Qoute>

      {/* <div className="mt-16">
        <BestilDagensRet aar={aar} uge={uge} />
      </div> */}
    </Layout>
  )
}

export default Index
