import BlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import React from "react"
import { blockSerializer } from "../utils/sanity-helper"
import { dataset, projectId } from "../utils/sanityConfig"
import { getFluidImage } from "../utils/sanityImage"
import DualPaneImage from "./dual-pane-image"

export interface BannerType {
  link?: string
  _rawImage: any
  _rawIndhold: any
  image: any
}

interface Props {
  banner: BannerType
  imageRight?: boolean
}

function InnerBanner({ banner, imageRight = false }: Props) {
  return (
    <DualPaneImage
      imageRight={imageRight}
      image={getFluidImage(
        banner._rawImage,
        banner.image.asset.fluid,
        576,
        330
      )}
    >
      <BlockContent
        blocks={banner._rawIndhold}
        serializers={blockSerializer("")()}
        projectId={projectId}
        dataset={dataset}
      />
    </DualPaneImage>
  )
}

export function Banner({ banner, imageRight = false }: Props) {
  if (banner.link)
    return (
      <Link to="/catering">
        <InnerBanner banner={banner} imageRight={imageRight} />
      </Link>
    )
  else return <InnerBanner banner={banner} imageRight={imageRight} />
}
